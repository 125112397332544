<template>
  <div class="mt-3">
    <div class="mt-5 ml-6 border-b-2 border-slate-300 max-w-xl">
      <FloatLabel variant="on">
        <Select
          id="comment"
          ref="commentInputRef"
          v-model="commentComputed"
          :options="commentComputed ? [commentComputed] : []"
          class="border-none rounded-none shadow-none"
          maxlength="15000"
          data-test-id="purchase-comment"
          :pt:overlay:class="'hidden'"
          :fluid="true"
          :disabled="false"
          @click="showCommentPanel"
        />
        <label for="purchase-comment">{{ t("purchase.comment.label") }}</label>
      </FloatLabel>
    </div>
    <CommentPanel ref="commentPanelRef" v-model:comment="commentComputed" @tab-key-pressed="tabKeyPressed" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  comment: string;
}>();
const emit = defineEmits<{
  (e: "update:comment", value: string): void;
}>();

const commentComputed = computed<string>({
  get: () => {
    return props.comment.trim() ?? "";
  },
  set: (value) => {
    emit("update:comment", value);
  },
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};
const commentInputRef = ref();
const tabKeyPressed = () => commentInputRef.value.$el?.querySelector("button")?.focus();
</script>
