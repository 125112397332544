<template>
  <ContextMenu ref="ellipsisContextMenuRef" :model="menuComputed" />
</template>

<script setup lang="ts">
import ContextMenu from "primevue/contextmenu";
import type { MenuItem } from "primevue/menuitem";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  (event: "openProductInfo"): void;
  (event: "selectRow"): void;
}>();

const ellipsisContextMenuRef = ref();

const menuComputed = computed<MenuItem[]>(() => [
  {
    label: t("purchase.context-menu.edit"),
    icon: "pi pi-fw pi-pencil",
    command: () => {
      emit("selectRow");
    },
  },
  {
    label: t("purchase.context-menu.product-info"),
    icon: "pi pi-fw pi-info",
    command: () => {
      emit("openProductInfo");
    },
  },
]);

const openMenu = (event: Event) => {
  if (ellipsisContextMenuRef.value !== undefined) {
    ellipsisContextMenuRef.value.show(event);
  } else {
     
    console.error("Context menu reference is not initialized.");
  }
};
defineExpose({ openMenu });
</script>
