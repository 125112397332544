<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      {{ props.label }}
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        severity="secondary"
        text
        :class="{ 'c-toolbar-button-active': props.isChooserVisible }"
        @click="(event: Event) => emit('openColumnChooser', event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  label: string;
  isChooserVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "openColumnChooser", event: Event): void;
}>();
</script>
