<template>
  <div class="mt-3">
    <FloatLabelInput
      id="contact-search-input"
      v-model:value="supplierContactComputed"
      :label="t('purchase.contact.label')"
      :select-all-on-focus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.contact.label').toLowerCase() })"
      :data-test-id="'contact-search-input'"
      class="p-disabled"
      :disabled="true"
    />
  </div>
</template>

<script setup lang="ts">
import { Supplier } from "@/repository/goods-reception/model/Supplier";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  supplierContact: Supplier;
}>();

const emit = defineEmits<{
  (e: "update:supplierContact", value: string): void;
}>();

const supplierContactComputed = computed<string>({
  get: () => {
    return (props.supplierContact?.contact.firstName + " " + props.supplierContact.contact?.lastName).trim() ?? "";
  },
  set: (value) => {
    emit("update:supplierContact", value);
  },
});
</script>
