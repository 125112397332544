<template>
  <div class="mt-3">
    <FloatLabelInput
      id="contact-email"
      v-model:value="purchaseOrderReferenceComputed"
      :label="t('purchase.contact.email-label')"
      :select-all-on-focus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.contact.email-label').toLowerCase() })"
      :data-test-id="'contact-email'"
      class="p-disabled"
      :disabled="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const purchaseOrderReferenceComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});
</script>
