<template>
  <FloatLabelInput
    id="freight-method"
    v-model:value="selectedFreightMethod"
    data-test-id="freight-method"
    :disabled="true"
    class="p-disabled"
    :label="t(`purchase.freight-method`)"
    :placeholder="t('placeholder.select', { property: t('purchase.freight-method').toLowerCase() })"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  freightMethod: string;
}>();

const emit = defineEmits<{
  (e: "update:freightMethod", value: string): void;
}>();

const selectedFreightMethod = computed<string>({
  get: () => {
    return props.freightMethod ?? "";
  },
  set: (value) => {
    emit("update:freightMethod", value);
  },
});
</script>
