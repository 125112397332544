<template>
  <FloatLabelInput
    id="purchase-incoterms"
    v-model:value="incotermsComputed"
    data-test-id="purchase-incoterms"
    :label="t(`purchase.incoterms`)"
    :placeholder="t('placeholder.select', { property: t('purchase.incoterms').toLowerCase() })"
    :select-all-on-focus="true"
    :disabled="true"
    class="p-disabled"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const { t } = useI18n();

const props = defineProps<{
  selectedIncoterms: string | null;
}>();

const emit = defineEmits<{
  (e: "update:selectedIncoterms", value: string | null): void;
}>();

const incotermsComputed = computed<string>({
  get: () => {
    return props.selectedIncoterms ?? "";
  },
  set: (value) => {
    emit("update:selectedIncoterms", value);
  },
});
</script>
