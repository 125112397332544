<template>
  <div class="my-4" :class="{ 'c-right-sidebar': props.showSidebar }">
    <div class="col-span-12 mb-3">
      <CumulusDatePicker
        v-model:date="fromDateComputed"
        :label="t('from-date')"
        data-test-id="c-filter-from-date"
        :debounce-time="400"
      />
    </div>
    <div class="col-span-12">
      <CumulusDatePicker
        v-model:date="toDateComputed"
        :label="t('to-date')"
        data-test-id="c-filter-to-date"
        :debounce-time="400"
      />
    </div>
  </div>
  <hr class="mt-6 mb-6" />
  <div class="text-center">
    <PurchaseOrderStatusCheckboxList v-model:selected-statuses="statusesComputed" />
  </div>
  <hr class="mt-6 mb-6" />
  <div class="field text-center">
    <SelectWarehouse
      v-model:selected-warehouse-ids="selectedWarehouseComputed"
      :warehouses="warehouses"
      data-test-id="c-warehouse-filter"
    />
  </div>
  <hr class="mt-6 mb-6" />
  <div class="field text-center">
    <ChipsInput
      v-model:values="supplierNumbersComputed"
      :label="t('supplier-number')"
      data-test-id="c-supplier-number-filter"
      input-id="supplier-number"
    />
  </div>
  <hr class="mt-6 mb-6" />
  <div class="field text-center">
    <ChipsInput
      v-model:values="productNumbersComputed"
      :label="t('product-number')"
      data-test-id="c-product-number-filter"
      input-id="product-number"
    />
  </div>
  <hr class="mt-6 mb-6" />
  <div class="field text-center">
    <ChipsInput
      v-model:values="purchaseOrderNumbersComputed"
      :label="t('purchase-order-number')"
      data-test-id="c-purchase-order-number-filter"
      input-id="purchase-order-number"
    />
  </div>
  <hr class="mt-6 mb-6" />
  <div class="field text-center">
    <ChipsInput
      v-model:values="supplierProductNumbersComputed"
      :label="t('supplier-product-number')"
      data-test-id="c-supplier-product-number-filter"
      input-id="supplier-product-number"
    />
  </div>
</template>

<script setup lang="ts">
import { CumulusDatePicker } from "@cumulus/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import SelectWarehouse from "../SelectWarehouse.vue";
import ChipsInput from "../ChipsInput.vue";
import PurchaseOrderStatusCheckboxList from "./PurchaseOrderStatusCheckboxList.vue";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";

const props = defineProps<{
  showSidebar: boolean;
  filters: PurchaseOrderFilters;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: PurchaseOrderFilters): void;
}>();

const { t } = useI18n();

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const supplierNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierNumbers: value };
    emit("update:filters", filters);
  },
});

const supplierProductNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierProductNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierProductNumbers: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const purchaseOrderNumbersComputed = computed<string[]>({
  get: () => props.filters.purchaseOrderNumber,
  set: (value) => {
    const filters = { ...props.filters, purchaseOrderNumber: value };
    emit("update:filters", filters);
  },
});

const selectedWarehouseComputed = computed<string[]>({
  get: () => props.filters.warehouseIds,
  set: (value) => emit("update:filters", { ...props.filters, warehouseIds: value }),
});

const statusesComputed = computed<PurchaseOrderStatus[]>({
  get: () => props.filters.purchaseOrderStatuses,
  set: (value: PurchaseOrderStatus[]) => {
    const filters = { ...props.filters, purchaseOrderStatuses: value };
    emit("update:filters", filters);
  },
});
</script>

<style lang="scss" scoped>
.c-filter-header-container {
  justify-content: center;
  display: flex;
  align-items: center;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
