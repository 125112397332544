<template>
  <InputNumber
    :id="id"
    ref="inputRef"
    :input-class="inputClass"
    :model-value="value"
    :suffix="suffix"
    :allow-empty="allowEmpty"
    :min="minValue"
    :max="maxValue"
    :min-fraction-digits="digits"
    :max-fraction-digits="digits"
    :locale="locale"
    :data-testid="id"
    :disabled="disabled ? !!disabled : undefined"
    :mode="currencyIso ? 'currency' : 'decimal'"
    :currency="currencyIso"
    currency-display="narrowSymbol"
    @focus="selectAllOnFocus"
    @update:model-value="onUpdate($event)"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const inputRef = ref();

const props = defineProps<{
  id: string;
  disabled?: boolean;
  value: number;
  inputClass?: string | undefined;
  digits?: number | undefined;
  minValue?: number | undefined;
  maxValue?: number | undefined;
  suffix?: string | undefined;
  allowEmpty?: boolean | undefined;
  currencyIso?: string | undefined;
  setFocus?: boolean | undefined;
}>();

const emit = defineEmits<{
  (event: "onUpdateValue", value: number): void;
}>();

const { locale } = useI18n();

const selectAllOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};

const onUpdate = (value: number) => {
  const amount = props.value;
  const options = {
    style: "decimal" as const,
    minimumFractionDigits: props.digits,
    maximumFractionDigits: props.digits,
  };
  // Using en-US because else we can't convert the string to a number properly
  const numberFormat = new Intl.NumberFormat("en-US", options);
  const formattedValue = parseFloat(numberFormat.format(amount));

  if (value !== null && value !== formattedValue) {
    emit("onUpdateValue", value);
  }
};

onMounted(() => {
  if (props.setFocus) {
    const el = inputRef.value.$el as HTMLElement;
    if (el != null) {
      el?.querySelector("input")?.focus();
    }
  }
});
</script>
