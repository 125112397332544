<template>
  <ul v-if="hasActiveFilters" class="flex pl-3 mb-3" data-testid="c-active-filters-container">
    <li class="mr-3 px-2 text-white rounded-2xl bg-[#4a728f] flex items-center">
      <span data-testid="c-clear-active-filters-label">{{ t("search.clear-filter") }}</span>
      <span
        class="ml-2 cursor-pointer pi pi-times-circle"
        data-testid="c-clear-active-filters-btn"
        @click="clearFilters"
      >
      </span>
    </li>

    <ActiveFilterChip
      v-if="filtersComputed.fromDate !== null"
      :filter-value="d(filtersComputed.fromDate)"
      filter-type="fromDate"
      data-test-id="c-active-filters-from-date"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.toDate !== null"
      :filter-value="d(filtersComputed.toDate)"
      filter-type="toDate"
      data-test-id="c-active-filters-to-date"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.warehouseIds.length > 0"
      :filter-value="warehouseNamesComputed"
      filter-type="warehouseIds"
      data-test-id="c-active-filters-warehouse-id"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.purchaseOrderStatuses.length > 0"
      :filter-value="filtersComputed.purchaseOrderStatuses.map((status) => t(`status.${status.toLowerCase()}`))"
      filter-type="purchaseOrderStatuses"
      data-test-id="c-active-filters-goods-reception-status"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.supplierNumbers.length > 0"
      :filter-value="filtersComputed.supplierNumbers"
      filter-type="supplierNumbers"
      data-test-id="c-active-filters-supplier-number"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.productNumbers.length > 0"
      :filter-value="filtersComputed.productNumbers"
      filter-type="productNumbers"
      data-test-id="c-active-filters-product-number"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.purchaseOrderNumber.length > 0"
      :filter-value="filtersComputed.purchaseOrderNumber"
      filter-type="purchaseOrderNumber"
      data-test-id="c-active-filters-purchase-order-number"
      @remove-filter="removeFilter"
    />

    <ActiveFilterChip
      v-if="filtersComputed.supplierProductNumbers.length > 0"
      :filter-value="filtersComputed.supplierProductNumbers"
      filter-type="supplierProductNumbers"
      data-test-id="c-active-filters-supplier-product-number"
      @remove-filter="removeFilter"
    />
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import { useI18n } from "vue-i18n";
import ActiveFilterChip from "../ActiveFilterChip.vue";

const { t, d } = useI18n();

const props = defineProps<{
  filters: PurchaseOrderFilters;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "update:filters", value: PurchaseOrderFilters): void;
}>();

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: PurchaseOrderFilters) => {
    emit("update:filters", value);
  },
});
const removeFilter = (filterType: keyof PurchaseOrderFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof PurchaseOrderFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType as "fromDate" | "toDate"] = null;
  }

  emit("update:filters", filters);
};

const warehouseNamesComputed = computed<string[]>(() => {
  return props.filters.warehouseIds.map((id) => {
    const warehouse = props.warehouses.find((w) => w.id === id);
    return warehouse?.name ?? "";
  });
});

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.purchaseOrderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.supplierNumbers.length > 0 ||
    props.filters.supplierProductNumbers.length > 0 ||
    props.filters.warehouseIds.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});

const clearFilters = () => {
  const filters = new PurchaseOrderFilters();
  emit("update:filters", filters);
};
</script>
